<template>
  <section>
    <!-- Данный компонент создан под отображение заказанных товаров со страницы имеющей модификаторы -->
    <EditableProducts />
    <ReceiptSend />
    <PayMethods />
  </section>
</template>

<script>
import EditableProducts from "./AvailableItemsBlock/EditableProducts";
import ReceiptSend from "./ReceiptSend";
import PayMethods from "./PayMethods";

export default {
  components: {
    EditableProducts,
    ReceiptSend,
    PayMethods,
  },
};
</script>
